import React from "react";
import ContactApp from "./WebsitePages/contactPage";
// import Captcha from "./Form/captcha";
// import Phone from "./phone";
import Subscribe from "./WebsitePages/subscribe";
// import Getpricing from "./WebsitePages/Getpricing";
// import TableData from "./DBTable/TableData";
// import Admin from "./WebsitePages/Admin";

function App() {
  return (
    <div className="App">
      {/* <TableData /> */}
      {/* <Admin /> */}
      {/* <Getpricing/> */}
      {/* <ContactApp /> */}
      <Subscribe />
    </div>
  );
}
export default App;

