import React, { useState } from "react";
import Axios from "axios";
import { useRef } from "react";
import { Typography } from "@mui/material";

function Subscribe() {
  const form = useRef();

  const [user, setUser] = useState({
    name: "",
    email: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setUser((prev) => {
      return { ...user, [name]: value };
    });
  };
  const [state, setState] = useState();

  const dbResponse = () => {
    Axios.post("https://biztekminds.com/mycoolapp/api/insert2", {
      name: user.name,
      email: user.email,
    })
      .then((response) => {
        setState(response.data.message);
        // console.log(response.data.message);
        // alert(JSON.stringify(response.data.message));
      })
      .catch(function (error) {
        setState(error.message + " Please Try again later");
        console.log(
          "There has been a problem with your fetch operation: " + error.message
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dbResponse();
    setTimeout(() => setState(""), 5000);
    // Axios.get('https://biztekminds.com/mycoolapp/api/get2')
    Axios.post("https://biztekminds.com/mycoolapp/api/insert2", {
      name: user.name,
      email: user.email,
    }).then((response) => {
      // console.log(response.data.message);
      alert(JSON.stringify(response.data.message));
    });
    setUser({
      name: "",
      email: "",
    });
  };

  const { name, email } = user;
  const enabled = name.length > 0 && email.length > 0;

  return (
    <section className="newsletter bg-sand">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-12">
            <h4 className="text-center text-md-left">
              <span className="text-primary">Subscribe</span> <br />
              For Latest <span className="text-primary">Update</span>
            </h4>
          </div>
          <div className="col-lg-9 col-12">
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={user.name}
                  onChange={handleInput}
                  id="exampleInputName"
                  aria-describedby="userName"
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={user.email}
                  onChange={handleInput}
                  id="exampleInputEmail"
                  aria-describedby="userEmail"
                  placeholder="Your email"
                  required
                />
              </div>
              <div className="#">
                <button
                  type="submit"
                  disabled={!enabled}
                  onSubmit={handleSubmit}
                  className="btn btn-primary btn-default bold w-100"
                >
                  Subscribe
                </button>
              </div>
              <br />
            </form>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              <div style={{ color: "red" }}>{state}</div>
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Subscribe;
